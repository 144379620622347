@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

:root {
	--borderColor: #eaeaea;
}

html,
body {
	width: 100%;
	height: 100%;
}

body {
	font-size: 14px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

main {
	flex-grow: 1;
}

header,
main,
footer {
	flex-shrink: 0;
}

hr {
	border-top: 1px dotted var(--borderColor);
}

h1 {
	font-size: 1.5em;
}

pre {
	&.normal {
		font-family: inherit;
		font-size: inherit;
		background: floralwhite;
		display: inline-block;
		padding: 2em;
	}
}

img {
	display: block;
}

figure {
	padding: 1em;
	border: 1px solid #eee;
	box-shadow: 3px 3px 6px #ddd;
	margin: 3em auto;
	transform: rotate(-4deg);
	width: 75%;
}

figcaption {
	margin: 2rem 0 1rem 0;
	font-family: 'Reenie Beanie', cursive;
	font-size: 2em;
	color: inherit;
	h4 {
		text-transform: unset;
		letter-spacing: -1px;
		margin: 0;
		font-family: inherit;
		font-weight: inherit;
	}
}

blockquote {
	font-style: italic;
}

.about a {
	color: inherit;
	text-decoration: none;
}

.post-list {
	border-bottom: 1px dotted var(--borderColor);
	.read-more {
		margin: 1.25em 0;
	}
}

.paginator {
	.disabled {
		display: none;
	}
}
